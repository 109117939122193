import styled, { css } from 'styled-components';
import { Button, Row, Typography } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

export const AnnouncementBanner = styled(Row)`
  &&& {
    background-color: ${theme.colors.accent};
    padding: 10px 10px 10px 20px;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface AnnouncementBannerDescriptionProps {
  $isMobile?: boolean;
}

export const AnnouncementBannerDescription = styled(
  Typography.Text
)<AnnouncementBannerDescriptionProps>`
  &&& {
    color: ${theme.colors.primaryDeep};
    font-size: ${(props) => (props.$isMobile ? '12px' : 'inherit')};
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
`;

interface ContainerProps {
  $isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 25px;
  max-width: ${theme.size.maxWidth};
  margin: 0 auto;
  padding: ${(props) => (props.$isMobile ? '0 30px' : '0 60px')};
`;

interface SuperGraphicProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const SuperGraphic = styled.div<SuperGraphicProps>`
  background-image: ${(props) =>
    props.$isMobile
      ? "url('/images/super-graphic-mobile.png')"
      : "url('/images/super-graphic-v7.png')"};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;

  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) => (props.$isInView ? 'scale(1)' : 'scale(1.25)')};

  transition-property: opacity, transform;
  transition-duration: 2s;
  transition-timing-function: ${timingFunctions('easeOutQuart')};
  transition-delay: 0s;
`;

export const Content = styled.div``;

interface LogoProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const Logo = styled.img<LogoProps>`
  position: relative;
  width: 70%;
  min-width: 250px;
  margin-bottom: ${(props) => (props.$isMobile ? '10px' : '30px')};
  opacity: ${(props) => (props.$isInView ? 1 : 0)};

  transition-property: opacity;
  transition-duration: 3s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0s;
`;

interface CtaProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const Cta = styled(Button)<CtaProps>`
  &&& {
    display: block;
    min-width: 200px;
    margin: ${(props) => (props.$isMobile ? '20px 0 0' : '40px 0 0')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition:
      background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 1s ${timingFunctions('easeOutQuad')} 4s,
      transform 1s ${timingFunctions('easeOutQuad')} 4s;
  }
`;
