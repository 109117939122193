import { useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Col } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { useInView } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

import { GET_HOMEPAGE_FAQ_QUERY } from 'queries';

import { FAQProps } from 'types/help';

import AnimatedTitle from 'components/AnimatedTitle';

import { flattenEntities } from 'utils/graphql';

import theme from 'styles/theme';

import {
  FAQBackground,
  FAQContainer,
  FAQCollapse,
  FAQCollapsePanel,
  FAQCollapsePanelTitle,
  FAQCollapsePanelAnswer,
} from './styles';

export interface HomePageFAQProps {
  copy: any;
}

export default function HomePageFAQ({ copy }: HomePageFAQProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const { data } = useQuery(GET_HOMEPAGE_FAQ_QUERY);
  const faq: [FAQProps] = flattenEntities(data?.faqs)?.data;

  return (
    <FAQBackground>
      <FAQContainer
        ref={containerRef}
        $isInView={isInView}
        $isMobile={!screens.md}
      >
        <Col span={24}>
          <AnimatedTitle
            text={copy.title}
            level={screens.md ? 1 : 3}
            isInView={isInView}
            style={{
              color: theme.colors.primary,
              textAlign: 'center',
              margin: '0 auto',
              marginBottom: !screens.md ? '50px' : '70px',
            }}
          />
        </Col>
        <Col span={24}>
          <FAQCollapse
            accordion
            ghost
            expandIcon={(panelProps) =>
              panelProps.isActive ? (
                <MinusCircleFilled
                  style={{
                    fontSize: 24,
                    color: theme.colors.primarySoft,
                    paddingTop: screens.md ? 8 : 8,
                  }}
                />
              ) : (
                <PlusCircleFilled
                  style={{
                    fontSize: 24,
                    color: theme.colors.primaryDeep,
                    paddingTop: screens.md ? 8 : 8,
                  }}
                />
              )
            }
          >
            {faq?.map((item, index) => (
              <FAQCollapsePanel
                $isMobile={!screens.md}
                $isInView={isInView}
                className={`FAQCollapsePanel_${index}`}
                header={
                  <FAQCollapsePanelTitle level={5} $isMobile={!screens.md}>
                    {item.question}
                  </FAQCollapsePanelTitle>
                }
                key={index}
              >
                <Col md={{ span: 24 }}>
                  <FAQCollapsePanelAnswer level={screens.md ? 4 : 5}>
                    {item.answer}
                  </FAQCollapsePanelAnswer>
                </Col>
              </FAQCollapsePanel>
            ))}
          </FAQCollapse>
        </Col>
      </FAQContainer>
    </FAQBackground>
  );
}
