import { useState, useMemo } from 'react';
import { Grid } from 'antd';
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { VC_AVG_RETURNS } from 'config';

// import RiskWarningModal from './RiskWarningModal';

import { currencyFormatter, abbreviateNumber } from 'utils/strings';

import theme from 'styles/theme';
import {
  ReturnsProjectorChartWrapper,
  LineChartTooltipWrapper,
  LineChartTooltipTitle,
  LineChartTooltipData,
  LineChartTooltipDataItem,
  LineChartTooltipDataItemAsset,
  LineChartTooltipDataItemValue,
} from './styles';

interface SyndicateReturnsProjectorProps {
  sumOverride?: number;
}

function SyndicateReturnsProjector({
  sumOverride,
}: SyndicateReturnsProjectorProps) {
  const screens = Grid.useBreakpoint();

  const [investmentPeriod, setInvestmentPeriod] = useState<10 | 15 | 25>(15);
  const [riskWarningModalOpen, setRiskWarningModalOpen] = useState(false);

  const CustomLineChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <LineChartTooltipWrapper>
          <LineChartTooltipTitle level={5}>
            {payload[0].payload.name}
          </LineChartTooltipTitle>
          <LineChartTooltipData>
            {payload.map((item) => (
              <LineChartTooltipDataItem key={item.name} $color={item.color}>
                <LineChartTooltipDataItemAsset>
                  {item.name}:
                </LineChartTooltipDataItemAsset>
                <LineChartTooltipDataItemValue>
                  €{abbreviateNumber(item.value, item.value > 1000000 ? 1 : 1)}
                </LineChartTooltipDataItemValue>
              </LineChartTooltipDataItem>
            ))}
          </LineChartTooltipData>
        </LineChartTooltipWrapper>
      );
    }

    return null;
  };

  const generateFutureReturns = useMemo(() => {
    const startYear = 5; // Start recording returns from the 5th year
    const volatility = 0;
    const projectionArray = [];
    let futureValue = sumOverride; // Total amount invested to date
    let totalInvested = sumOverride; // Keep track of total amount invested over time

    for (let year = 1; year <= investmentPeriod + startYear; year++) {
      // Add quarterly investments for the current year to the total invested
      totalInvested += year > 1 ? sumOverride * 4 : 0;

      // Apply a random fluctuation to the annual return
      const randomFluctuation = (Math.random() * 2 - 1) * volatility;
      const adjustedAnnualReturn = VC_AVG_RETURNS + randomFluctuation;

      // Calculate the future value for the current year
      futureValue =
        futureValue * (1 + adjustedAnnualReturn) +
        (year > 1 ? sumOverride * 4 : 0);

      // Only start recording returns after 5 years
      if (year > startYear) {
        // Calculate net profit (future value - total invested)
        let netProfit = futureValue - totalInvested;
        netProfit = netProfit > 0 ? netProfit : 0; // Ensure net profit is not negative

        // Calculate carry on the net profit
        let carryEarned = netProfit * 0.1; // Assuming the carry percentage is 10%

        // Create the projection object for the current year and append it to the projection array
        let projectionObject = {
          name: `${new Date().getFullYear() + year - 1}`,
          'Estimated profit': carryEarned,
        };

        projectionArray.push(projectionObject);
      }
    }

    return projectionArray;
  }, [investmentPeriod, sumOverride]);

  return (
    <>
      {/* <RiskWarningModal
        isOpen={riskWarningModalOpen}
        setIsOpen={setRiskWarningModalOpen}
      /> */}
      <ReturnsProjectorChartWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={generateFutureReturns}>
            <Tooltip
              content={CustomLineChartTooltip}
              isAnimationActive={true}
            />
            <CartesianGrid strokeDasharray="0.5 3" />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#999' }}
              tickMargin={10}
            />
            <YAxis
              width={55}
              tickFormatter={(value) => `€${abbreviateNumber(value)}`}
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#999' }}
              orientation="right"
            />
            <Line
              type="monotone"
              dataKey="Estimated profit"
              stroke={theme.colors.primaryDeep}
              strokeWidth={1}
              dot={{ strokeWidth: 1, r: 2 }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </ReturnsProjectorChartWrapper>
    </>
  );
}

export default SyndicateReturnsProjector;
