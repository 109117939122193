import { useRef, useMemo, useState, useEffect } from 'react';
import { Space, Affix, Grid } from 'antd';
import { useInView } from 'framer-motion';
import Marquee from 'react-fast-marquee';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';

import theme from 'styles/theme';
import {
  Wrapper,
  Container,
  Content,
  SuperGraphic,
  Logo,
  Cta,
  AnnouncementBanner,
  AnnouncementBannerDescription,
} from './styles';

export interface HomePageHeroProps {
  copy: any;
}

export default function HoldingPage({ copy }) {
  const screens = Grid.useBreakpoint();
  const holdingRef = useRef(null);
  const isInView = useInView(holdingRef, { once: true });

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState(copy.cta);

  useEffect(() => {
    if (localStorage.getItem('PitchedIt-waitlistId')) {
      setWaitlistCtaCopy('Your waitlist status');
    }
  }, []);

  const getStyleForTitle = useMemo(() => {
    if (screens.xl)
      return {
        fontSize: '30px',
        lineHeight: '40px',
      };
    return { fontSize: '28px', lineHeight: '38px' };
  }, [screens]);

  return (
    <>
      <WaitlistModal
        isOpen={waitlistModalOpen}
        setIsOpen={setWaitlistModalOpen}
        onComplete={() => {}}
      />
      <Wrapper>
        <AnnouncementBanner align="middle" justify="space-between">
          <AnnouncementBannerDescription $isMobile={!screens.sm}>
            We’re thrilled to announce that PitchedIt has rebranded to Shuttle
            and received authorisation from the Central Bank of Ireland to
            operate across Europe!
          </AnnouncementBannerDescription>
        </AnnouncementBanner>
        <SuperGraphic $isMobile={!screens.sm} $isInView={isInView} />
        <Container ref={holdingRef} $isMobile={!screens.sm}>
          <Content>
            <Logo
              $isMobile={!screens.sm}
              $isInView={isInView}
              src="/images/shuttle-logo.svg"
              alt="Shuttle"
            />
            <AnimatedTitle
              text="Simple and unintimidating access to investment opportunities once reserved for the ultra-wealthy."
              isInView={isInView}
              delay={1}
              style={{
                ...getStyleForTitle,
                color: theme.colors.white,
                fontWeight: 300,
                maxWidth: 700,
              }}
            />
            <Cta
              $isMobile={!screens.sm}
              $isInView={isInView}
              size={screens.sm ? 'large' : 'large'}
              onClick={() => setWaitlistModalOpen(true)}
            >
              Join the club
            </Cta>
          </Content>
        </Container>
      </Wrapper>
    </>
  );
}
